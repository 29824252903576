//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-400:_1444,_3240,_4260,_4192,_2796,_5026,_8016,_1816;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-400')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-400', "_1444,_3240,_4260,_4192,_2796,_5026,_8016,_1816");
        }
      }catch (ex) {
        console.error(ex);
      }