import shopifyGenericDefaults from '../shopify-ella-generic/config.js';

const tiresFields = shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

export default {
  includes: ['shopify-ella-generic'],
  ...shopifyGenericDefaults,
  SearchRequestDefaults: {
    ...shopifyGenericDefaults.SearchRequestDefaults,
    extra: {
      ...shopifyGenericDefaults.SearchRequestDefaults.extra,
      doNotHideWheelsTiresFacets: window.location.pathname === '/',
    },
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((w) => !['SearchBox'].includes(w.name)),
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: { replace: '.search-modal__form', class: 'cm-search-box' },
      onDropdownItemsReceived: () =>
        shopifyGenericDefaults.Widgets.find((widget) => widget.name === 'SearchBox').onDropdownItemsReceived,
      template: 'searchBox/dialogButtonAdaptable',
      visibleIf: () => window.Convermax?.config?.isSearchBoxEnabled,
    },
    {
      name: 'HomeVehicleWidget_tab',
      type: 'VehicleWidget',
      location: {
        selector: '#cm-home-ymm',
        class: 'cm_vehicle-widget__tabs',
      },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 650,
    },
    {
      name: 'TirePanel_tab',
      type: 'RequestPanel',
      location: {
        selector: '#cm-home-tire',
        class: 'cm_vehicle-widget__tabs',
      },
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: tiresFields,
    },
  ],
};
