export default {
  BUTTON_PRIMARY_CLASS: 'cm_button button button--primary',
  BUTTON_SECONDARY_CLASS: 'cm_button button button--secondary',

  SEARCH_BOX_CLASS: 'search search-modal__form',
  SEARCH_BOX_FORM_CLASS: 'field',
  SEARCH_BOX_INPUT_CLASS: 'search__input field__input form-input-placeholder',
  SEARCH_BOX_BUTTON_CLASS: 'button search__button field__button focus-inset',
  SEARCH_BOX_PLACEHOLDER: 'Search the store',
  SEARCH_BOX_DIALOG_OPENING_ICON_CLASS: 'header__icon',

  PRODUCT_REPEATER_CLASS: 'productListing list-unstyled productGrid grid-{this.view}',

  FACET_BAR_WHEELS_TIRES_TITLE_CLASS: 'facettitle',
  FACET_BAR_WHEELS_TIRES_CONTAINER_CLASS: 'facetholder',
  FACET_BAR_WHEELS_TIRES_BODY_CLASS: 'facetbody',

  LOAD_MORE_TEXT: 'SHOW MORE',
  LOAD_MORE_BUTTON_CLASS: 'button--secondary',

  CHANGE_VEHICLE: 'Change',
  DISCARD_VEHICLE: 'Clear',

  YOU_MAY_ALSO_LIKE: 'Related Products',
  RELATED_PRODUCT_CONTAINER_CLASS: 'halo-block halo-product-block halo-recommendations-block',
  RELATED_PRODUCT_TITLE_CONTAINER_CLASS: 'halo-block-header',
  RELATED_PRODUCT_TITLE_CLASS: 'title',
};
